@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

:root {
  /* FONT  */
  --font-family: "Lato", sans-serif;

  /* WEB COLORS */
  --secondBacground: #ffffff;
  --backGround: #f6f4f5;
  --primary: #1b2125;
  --secondary: #cf4a46;
  --acent: #f5dbda;
  /* TEXT COLORS */
  --textPrimary: #272b37;
  --textParagraph: #5f5f5f;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
  text-decoration: none;
}

body {
  background-color: var(--secondBacground);
  min-width: 300px;
}

.padding__szerokosKontenera {
  padding-right: 16rem;
  padding-left: 16rem;
}

.padding__wysokośćKontenera {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.margin__wysokoscSekcji {
  margin: 6rem 0;
}

.padding__szerokoscPrawy {
  padding-right: 16rem;
}

.padding__szerokoscLewy {
  padding-left: 16rem;
}

.padding__wysokośćKontenera {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.padding__wysokoscGora {
  padding-top: 6rem;
}

.padding__wysokoscDol {
  padding-bottom: 6rem;
}

@media screen and (max-width: 1024px) {
  .padding__szerokosKontenera {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .padding__szerokoscPrawy {
    padding-right: 4rem;
  }

  .padding__szerokoscLewy {
    padding-left: 4rem;
  }
  .padding__wysokośćKontenera {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .padding__wysokoscGora {
    padding-top: 4rem;
  }

  .padding__wysokoscDol {
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .padding__szerokosKontenera {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .padding__szerokoscPrawy {
    padding-right: 3rem;
  }

  .padding__szerokoscLewy {
    padding-left: 3rem;
  }
}

@media screen and (max-width: 550px) {
  .padding__szerokosKontenera {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .padding__szerokoscPrawy {
    padding-right: 1rem;
  }

  .padding__szerokoscLewy {
    padding-left: 1rem;
  }
  .padding__wysokośćKontenera {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .padding__wysokoscGora {
    padding-top: 2rem;
  }

  .padding__wysokoscDol {
    padding-bottom: 2rem;
  }
}

.section-title {
  font-size: 44px;
  font-weight: bold;
  color: var(--textPrimary);
  margin-bottom: 2rem;
}

.text-paragraph {
  color: var(--textParagraph);
  font-size: 22px;
  line-height: 28px;
}

@media screen and (max-width: 768px) {
  .section-title {
    font-size: 44px;
  }

  .text-paragraph {
    font-size: 22px;
  }
}

@media screen and (max-width: 550px) {
  .section-title {
    font-size: 32px;
  }

  .text-paragraph {
    font-size: 16px;
  }
}
