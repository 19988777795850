.header__nav-container_mobile {
  display: none;
}

.header__kontener {
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  background-size: cover;
  position: relative;
}

.header__kontener-nakladka {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--layer);
}

.header__kontenerSekcji {
  color: var(--backGround);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.header__slider-kontener {
  margin-top: 16rem;
  width: 60%;
  height: 100%;
}

.header__slider-headSection {
}

.header__slider-headSection h4 {
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 1rem;
}

.header__slider-headSection h2 {
  font-size: 52px;
  margin-bottom: 2rem;
}

.header__slider-text p {
  font-size: 22px;
}

.header__slider-nav {
  margin-top: 2rem;
}

.header__slider-nav button {
  padding: 1rem 2rem;
  background-color: var(--backGround);
  border: none;
  font-size: 16px;
  color: var(--primary);
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  .menu__mobile {
    height: 100%;
    background-color: var(--primary);
    padding-bottom: 2rem;
  }

  .hamburgerMenu {
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
  }

  .hamburgerMenu button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    font-size: 36px;
    color: var(--secondBacground);
    cursor: pointer;
  }

  .header__nav-kontener {
    display: none;
  }

  .header__nav-container_mobile {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    background-color: var(--primary);
  }
  .header__slider-kontener {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .header__slider-kontener {
    margin-top: 6rem;
  }

  .header__slider-headSection h4 {
    font-size: 18px;
  }
  .header__slider-headSection h2 {
    font-size: 26px;
  }

  .header__slider-text p {
    font-size: 20px;
    text-align: justify;
  }

  /* .header__kontener {
    height: 70vh;
  } */
}
