.kazania__wrapper {
  width: 100%;
}

.kazania_head {
  display: flex;
  justify-content: space-between;
}

.kazania_head h1 {
  flex: 1;
}

.kazania_head p {
  flex: 1;
}

.kazania_sekcja-moduly {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.kazania__modul {
  margin-top: 4rem;
  width: 31.5%;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.25);
}

.kazania__modul-imgKontener {
  width: 100%;
  height: 300px;
}

.kazania__modul-imgKontener img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.kazania__modul-text {
  padding: 4rem 2rem;
}

.kazania__modul-text h3 {
  margin-top: 2rem;
  font-weight: 500;
  font-size: 32px;
}

.kazania__modul-text p {
  /* margin-bottom: 2rem; */
}

.kazania__modul-text p span {
  font-weight: 700;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  color: var(--secondary);
  background-color: var(--acent);
  font-size: 14px;
}

.kazania__span-border {
  margin-top: 1rem;
  display: block;
  width: 55px;
  border-bottom: 1px solid var(--textParagraph);
}

.kazania__data {
  margin-top: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  color: var(--textParagraph);
}

@media screen and (max-width: 1024px) {
  .kazania_head {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .text-paragraph {
    text-align: justify;
  }

  .kazania_sekcja-moduly {
    flex-wrap: wrap;
  }

  .kazania__modul {
    width: 49%;
  }
}

@media screen and (max-width: 768px) {
  .kazania_head {
    flex-direction: column;
    text-align: center;
  }

  .text-paragraph {
    text-align: justify;
  }

  .kazania_sekcja-moduly {
    flex-wrap: wrap;
  }

  .kazania__modul {
    width: 49%;
  }
}

@media screen and (max-width: 550px) {
  .kazania__modul {
    width: 100%;
  }
}
