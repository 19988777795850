.slogan__slogan-text {
  padding-left: 2rem;
  border-left: 4px solid var(--secondary);
}

.slogan__slogan-text h1 {
  font-style: italic;
}

.slogan__autor {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.slogan__autor {
  margin-top: 1rem;
}

.slogan__autor p {
  font-size: 22px;
}

@media screen and (max-width: 1024px) {
  .slogan__slogan-text h1 {
    font-size: 24px;
  }

  .slogan__autor p {
    font-size: 18px;
  }
}

@media screen and (max-width: 550px) {
  .slogan__slogan-text h1 {
    font-size: 17px;
  }

  .slogan__autor p {
    font-size: 16px;
  }
}
