.nav__kontener {
  padding-top: 3rem;
  color: var(--backGround);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav__menu {
  display: flex;
  justify-content: space-between;
}

.nav__menu p {
  margin-left: 2rem;
}

.nav__menu p a {
  font-size: 18px;
  color: var(--backGround);
  text-shadow: 0px 1px 2px #000000;
}

.nav__logo {
  display: flex;
  align-items: center;
}

.nav__logo-text p {
  font-size: 18px;
}

.nav__logo-container {
  margin-top: 10px;
}

.nav__logo-container p span {
  margin-right: 1rem;
  font-size: 48px;
  font-weight: 100;
}

@media screen and (max-width: 1024px) {
  .nav__logo-container p span {
    margin-right: 0;
  }
  .nav__kontener {
    padding-top: 0;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .nav__logo-container {
    margin-top: 0;
  }
  .nav__menu {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .nav__menu p {
    margin-left: 0;
    padding: 2rem 0;
    border-bottom: 2px solid var(--secondBacground);
    text-align: center;
    width: 100%;
  }

  .nav__menu p a {
    font-size: 22px;
  }
}

@media screen and (max-width: 550px) {
  .nav__logo {
    flex-direction: column;
  }

  .nav__menu {
    margin-top: 1rem;
  }
  .nav__menu p {
    border-bottom: 1px solid var(--secondBacground);
  }

  .nav__menu p a {
    font-size: 18px;
  }
}
