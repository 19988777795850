.about__kontener {
  background-color: var(--backGround);
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: space-between;
}

.about__zdjecie {
  flex: 1;
}

.about__zdjecie img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.about__text {
  flex: 1.5;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.about__text-kontener {
  padding: 0 16rem 0 6rem;
  color: var(--textPrimary);
}

.about__text-kontener h2 {
  margin-bottom: 2rem;
}

.about__text-kontener p {
  margin-bottom: 1rem;
  font-size: 22px;
  line-height: 28px;
}

.about__text-kontener button {
  margin-top: 1rem;
  border: none;
  background-color: var(--secondary);
  padding: 1rem 2rem;
  color: var(--backGround);
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  .about__kontener {
    min-height: auto;
  }

  .about__text-kontener {
    padding: 0 3rem 0 2rem;
    width: 100%;
  }
  .about__text-kontener p {
    text-align: justify;
  }
}
@media screen and (max-width: 550px) {
  .about__kontener {
    flex-direction: column;
  }

  .about__zdjecie {
    margin-bottom: 2rem;
  }

  .about__text-kontener {
    padding: 0 1rem;
  }

  .about__text-kontener p {
    font-size: 16px;
  }

  .about__text-kontener button {
    font-size: 14px;
    width: 100%;
  }
}
