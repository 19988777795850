.footer__kontener {
  width: 100%;
  height: 100%;
  background-color: var(--primary);
}

.footer__head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer__head h2,
.footer__head h3 {
  font-size: 110px;
  color: var(--backGround);
  margin-bottom: 0;
}

.footer__head h3 {
  font-size: 36px;
}

.footer__menu {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding-bottom: 4rem;
  border-bottom: 1px solid rgb(246, 244, 245, 0.3);
}

.footer__menu p {
  margin-left: 2rem;
}

.footer__menu p a {
  color: var(--backGround);
}

.footer__kontakt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-bottom: 1px solid rgb(246, 244, 245, 0.3);
}

.footer__kontakt p {
  color: var(--backGround);
  font-weight: bold;
  font-size: 18px;
}

.footer__kontakt h4 {
  margin-top: 1rem;
  color: var(--secondary);
  font-size: 36px;
}

.footer__adres {
  margin-top: 2rem;
  color: var(--backGround);
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer__adres p {
  margin-bottom: 1rem;
}

.footer__adres p:nth-child(3) {
  /* font-size: 14px; */
  color: rgb(246, 244, 245, 0.3);
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .footer__menu {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .footer__head h2 {
    text-align: center;
    font-size: 76px;
  }
  .footer__head h3 {
    text-align: center;
    font-size: 21px;
  }

  .footer__kontakt h4 {
    font-size: 22px;
  }
  .footer__adres p:nth-child(3) {
    font-size: 12px;
  }
}
