.oferta__wrapper {
  width: 100%;
  background-color: var(--backGround);
}

.oferta__kontener {
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
}

.oferta__head {
  display: flex;
  justify-content: center;
}

.oferta__head h4 {
}

.oferta__nabozenstwa-kontener {
  margin-top: -2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.oferta__nabozensta-modul {
  width: 24%;
}

.oferta__nabozensta-modul_imgKontener {
  width: 100%;
  height: 300px;
}

.oferta__nabozensta-modul_imgKontener img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.oferta__nabozenstwa-modul_textKontener {
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.oferta__nabozenstwa-modul_textKontener h3 {
  font-weight: 500;
  font-size: 16px;
  color: var(--secondary);
  margin-bottom: 1.5rem;
  text-align: center;
}

.oferta__nabozenstwa-modul_textKontener h2 {
  color: var(--textPrimary);
}

.oferta__nabozenstwa-modul_textKontener span {
  margin-top: 2rem;
  height: 1px;
  width: 50px;
  display: block;
  background-color: var(--secondary);
}

@media screen and (max-width: 1024px) {
  .oferta__nabozenstwa-kontener {
    flex-wrap: wrap;
  }

  .oferta__nabozensta-modul {
    width: 49%;
  }

  .oferta__nabozenstwa-modul_textKontener h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  .oferta__nabozenstwa-kontener {
    flex-wrap: wrap;
  }

  .oferta__nabozensta-modul {
    width: 49%;
  }

  .oferta__nabozenstwa-modul_textKontener h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 550px) {
  .oferta__nabozensta-modul {
    width: 100%;
  }

  .oferta__nabozenstwa-modul_textKontener {
    text-align: center;
  }
}
