.opine__wrapper {
  width: 100%;
  background-color: var(--primary);
}

.opinie__kontener {
  width: 100%;
  height: 100%;
  color: var(--backGround);
  display: flex;
  justify-content: space-between;
}

.opinie__head {
  flex: 1;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.opinie__head h3 {
  color: var(--backGround);
  margin-bottom: 0;
}

.opinie__head p {
  margin: 2rem 1rem 2rem 0;
  font-size: 20px;
  line-height: 32px;
}

.opinie__head button {
  color: var(--backGround);
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  padding: 1rem 2rem;
  background: none;
  border: 1px solid var(--backGround);
}

.opinie__komentarz {
  flex: 1;
  position: relative;
}

.opinie__komentarz-imgKontener {
  position: absolute;
  right: 0;
  width: 70%;
  height: 90%;
  z-index: 1;
}
.opinie__komentarz-imgKontener img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.opinie__komentarz-wpisKontener {
  position: absolute;
  bottom: 0;
  width: 90%;
  min-height: 50%;
  z-index: 2;
  background-color: var(--secondary);
}

.opinie__komentarz-paragraph {
  padding: 2rem 4rem;
}

.opinie__komentarz-paragraph p {
  font-size: 20px;
  padding-left: 1rem;
  border-left: 4px solid var(--backGround);
}

.opinie__person {
  padding: 0.5rem 4rem 2rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -1.5rem;
}

.opinie__person-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.opinie__person-img img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.opinie__person-name h4 {
  font-size: 32px;
}

.opinie__person-selector {
  display: flex;
}

.opinie__person-selector .span__empty {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 0.5rem;
  border: 2px solid rgb(255, 255, 255, 0.5);
}

.opinie__person-selector .span__empty:nth-child(2) {
  border: 2px solid var(--backGround);
  display: flex;
  align-items: center;
  justify-content: center;
}

.opinie__person-selector .span__active {
  display: block;
  background-color: var(--backGround);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 5;
}

@media screen and (max-width: 1024px) {
  .opinie__komentarz-wpisKontener {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .opinie__head p {
    font-size: 18px;
    text-align: justify;
  }

  .opinie__komentarz-paragraph p {
    font-size: 18px;
    text-align: justify;
  }

  .opinie__person-img {
    width: 50px;
    height: 50px;
  }
  .opinie__person-name h4 {
    font-size: 18px;
  }

  .opinie__person-selector .span__empty {
    width: 14px;
    height: 14px;
  }

  .opinie__person-selector .span__empty:nth-child(2) {
    border: 0.3px solid var(--backGround);
  }
  .opinie__person {
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .opinie__kontener {
    flex-direction: column;
  }
  .opinie__person {
    margin-top: 0;
    padding: 0;
  }
  .opinie__komentarz-wpisKontener {
    position: relative;
    width: 100%;
    padding: 2rem 0;
  }
  .opinie__komentarz-imgKontener img {
    display: none;
  }
  .opinie__person {
    gap: 20px;
  }
}

@media screen and (max-width: 550px) {
  .opinie__head p {
    font-size: 15px;
  }

  .opinie__head button {
    font-weight: 500;
    font-size: 14px;
    width: 100%;
  }
  .opinie__komentarz-paragraph {
    padding: 1rem 1.5rem;
  }

  .opinie__komentarz-paragraph p {
    font-size: 16px;
    padding-left: 1rem;
    border-left: 2px solid var(--backGround);
  }

  .opinie__person-img {
    width: 35px;
    height: 35px;
  }
}
