.kontakt__kontener {
  width: 100%;
  height: 100%;
  background-color: var(--secondary);
}

.kontakt__head {
  color: var(--backGround);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.kontakt__head h4 {
  flex: 1;
  margin-bottom: 0;
  color: var(--backGround);
}

.kontakt__head p {
  padding-left: 2rem;
  flex: 1.5;
  font-size: 24px;
}

.kontakt__fomularz-kontener {
  margin-top: 4rem;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.kontakt__fomularz-kontener input,
.kontakt__fomularz-kontener button {
  height: 100%;
  width: 24%;
  border: none;
  font-size: 16px;
}

.kontakt__fomularz-kontener input {
  padding: 2rem;
}

.kontakt__fomularz-kontener button {
  font-weight: bold;
  color: var(--backGround);
  background-color: var(--textParagraph);
}

@media screen and (max-width: 1024px) {
  .kontakt__head {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .kontakt__head p {
    padding-left: 0;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }

  .kontakt__fomularz-kontener {
    min-height: 70px;
    height: auto;
    flex-direction: column;
  }

  .kontakt__fomularz-kontener input,
  .kontakt__fomularz-kontener button {
    width: 100%;
    font-size: 16px;
    margin-bottom: 1rem;
    height: 70px;
  }
}
