.team__kontener {
  width: 100%;
  height: 100%;
}

.team__head {
  margin-top: -2rem;
  display: flex;
  justify-content: center;
}

.team__module-kontener {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}

.team__module {
  width: 49%;
  height: 600px;
  position: relative;
}

.team__module-imgKontener {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.team__module-imgKontener img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team__module-text {
  position: absolute;
  bottom: 0;
  padding: 4rem 2rem;
  color: var(--backGround);
  z-index: 2;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.452);
}

.team__module-text h4 {
  font-size: 36px;
}

.team__module-text p {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .team__module-kontener {
    gap: 30px;
    flex-direction: column;
  }

  .team__module {
    width: 100%;
  }

  .team__module-text h4 {
    margin-bottom: 1rem;
  }
}
